<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">Campaign List</div>
    <div
      class="
        col-lg-8 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      "
    ></div>
  </div>
  <div class="custom-ultima-datatable">
    <DataTable
      :value="campaignList"
      :scrollable="true"
      scrollHeight="flex"
      :paginator="true"
      :rows="30"
      :lazy="true"
      :rowHover="true"
      :totalRecords="totalRecords"
      @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading"
      dataKey="ah1"
    >
      <template #empty>No Records found.</template>
      <template #loading>Loading data. Please wait...</template>
      <Column
        field="name"
        header="Campaign Name"
        headerStyle="width: 25%"
        bodyStyle="width: 25%"
      >
        <template #body="{ data }">
          <div>
            <div class="text-capitalize">
              {{ data.ah3 ? data.ah3 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column
        field="description"
        header="Template Body"
        headerStyle="width: 60%"
        bodyStyle="width: 60%"
      >
        <template #body="{ data }">
          <div>
            {{ data.ah5 ? data.ah5 : "N/A" }}
          </div>
        </template></Column
      >
      <Column
        field="category"
        header="Action"
        headerStyle="width: 15%"
        bodyStyle="width: 15%"
      >
        <template #body="{ data }">
          <div>
            <button
              type="button"
              class="btn custom-view-detail-btn mb-2 w-100"
              @click="campaignLogsRedirectPage(data)"
            >
              View Logs
            </button>
            <button
              type="button"
              class="btn custom-view-detail-btn w-100"
              @click="campaignReportRedirectPage(data)"
            >
              View Reports
            </button>
          </div>
        </template></Column
      >
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../service/ApiService";
import router from "@/router";
export default {
  data() {
    return {
      campaignList: [],
      totalRecords: 0,
      loading: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.loading = true;
    this.getcampaign();
  },
  methods: {
    getcampaign(item) {
      this.ApiService.getcampaign(item).then((data) => {
        if (data.success === true) {
          this.campaignList = data.records;
          this.totalRecords = data.totalcount;
          this.loading = false;
        } else {
          this.loading = false;
          this.campaignList = null;
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getcampaign({ page_no: this.page_no });
    },
    campaignLogsRedirectPage(data) {
      let routeData = router.resolve({
        name: "VoterCampaignLogs",
        params: { campaignId: data.ah1 },
      });
      window.open(routeData.href, "_blank");
    },
    campaignReportRedirectPage(data) {
      let routeData = router.resolve({
        name: "VoterCampaignReports",
        params: { campaignId: data.ah1 },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>